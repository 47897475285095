import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				King’s Cut
			</title>
			<meta name={"description"} content={"Досвідчені перукарі, стильні стрижки та царська атмосфера, щоб ви відчули себе королівською особою."} />
			<meta property={"og:title"} content={"King’s Cut"} />
			<meta property={"og:description"} content={"Досвідчені перукарі, стильні стрижки та царська атмосфера, щоб ви відчули себе королівською особою."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="link2" />
			<Override slot="text3" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-10">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Text margin="0px 0px 15px 0px" font="normal 700 42px/1.2 --fontFamily-sans" text-align="center">
				Ознайомтеся з нашими послугами
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#626970" text-align="center">
				У King’s Cut Barbershop ми пропонуємо більше, ніж просто послуги з догляду; ми створюємо королівський досвід, який починається з того моменту, коли ви входите в наш салон. Наші послуги розроблені для задоволення вимогливих клієнтів, забезпечуючи, що кожен аспект вашого догляду відповідає вашим найвищим очікуванням. Незалежно від того, чи шукаєте ви класичну стрижку або сучасний стиль, у нас є ідеальна пропозиція, яка чекає на вас.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-2.jpg?v=2024-08-12T10:53:12.861Z) center center/cover repeat scroll padding-box"
					height="250px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 10px 0px"
						lg-margin="0px 0px 15px 0px"
					/>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Наші перукарі:
							<br />
							{" "}
							<br />
							Майстри своєї справи, які створюють стилі, гідні королів.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-3.jpg?v=2024-08-12T10:53:12.861Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Послуги:
							<br />
							{" "}
							<br />
							Індивідуальні стрижки, гоління та догляд за бородою, які підкреслюють ваш стиль.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-4.jpg?v=2024-08-12T10:53:12.852Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Розкішна атмосфера:{" "}
							<br />
							<br />
							Простір, що створює відчуття комфорту та елегантності, гідне королівського візиту.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/1-2.jpg?v=2024-08-12T10:53:12.857Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Простий процес:{" "}
							<br />
							<br />
							Від запису до завершення послуги, ми дбаємо про кожну деталь, щоб ваше відвідування було незабутнім.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Приєднуйтеся до нашої королівської родини
			</Override>
			<Override slot="text1">
				Це лише короткий огляд того, що ми пропонуємо в King’s Cut Barbershop. Відкрийте для себе повну інформацію про наші послуги, зв’язавшись з нами або відвідавши нас особисто. Ми запрошуємо вас відчути повний спектр наших послуг і стати частиною нашої королівської родини. Наша команда з радістю познайомить вас із нашим салоном і допоможе вибрати ідеальний догляд для ваших потреб.
			</Override>
			<Override slot="box2" align-self="center" />
			<Override slot="image" src="https://uploads.quarkly.io/66b9c1f6e778710018d287c5/images/2-1.jpg?v=2024-08-12T10:53:12.866Z" />
		</Components.Cta>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});